import React, { Component } from "react";
import Helmet from "react-helmet";
import config from "../../data/SiteConfig";

import Layout from "../components/layout";
import SectionTitle from "../components/SectionTitle/SectionTitle";
import Content from "../components/Content/Content";
import Team from "../components/Team/Team";

import FullWidthImage2 from "../components/FullWidthImage2/FullWidthImage2";
// import img from "../imgs/american-public-power-association-419672-unsplash.jpg";
const img =
  "https://res.cloudinary.com/downtowndev/image/upload/q_auto,f_auto/american-public-power-association-419672-unsplash_c7cpaa.jpg";

class AboutPage extends Component {
  render() {
    return (
      <Layout location={this.props.location}>
        <div className="about-container underNavbar">
          <Helmet htmlAttributes={{ lang: "en" }} title={`About | ${config.siteTitle}`} />
          <FullWidthImage2 title="Our Team" image={img} />
          <Content className="page-content">
            <h2>Company Bio</h2>
            Sverdrup Engineering Services (SES) is an engineering firm focused on providing high end
            Electrical, Mechanical, Civil, and Instrumentation and Control services. SES was founded
            in 2012 with a determination to provide quality engineering services on schedule and at
            an affordable price tag.
            <h2 style={{ paddingTop: 30 }}>Mission Statement</h2>
            To provide clients with high quality architectural and engineering services, keeping
            projects under budget and on schedule.
            <h2 style={{ paddingTop: 30 }}>The Team</h2>
            <Team />
          </Content>
        </div>
      </Layout>
    );
  }
}

export default AboutPage;
